import React from "react"
import PropTypes from "prop-types"
import { GlobalStyle } from "@Assets/styles/globalStyle"
import Footer from "@Components/Footer"
import "../assets/styles/animation.css"
import BackgroundLines from "./BackgroundLines"
import Header from "./header"
import "../../static/fonts.css"
const Layout = ({ children, onModalOpen }) => {
  return (
    <>
      <GlobalStyle />
      <Header onModalOpen={onModalOpen} />
      <main>{children}</main>
      <Footer />
      <BackgroundLines />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
