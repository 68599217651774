import React from "react"
import { containerStyled, ctaStyled } from "@Assets/styles/globalStyle"
import { breakpoint } from "@Assets/styles/mixins"

import styled from "styled-components"
// import { variables } from "@Assets/styles/variables"

const Header = ({ onModalOpen }) => (
  <HeaderStyled>
    <HeaderWrapperStyled>
      <HeaderItemStyled>
        <HeaderCtaStyled onClick={onModalOpen}>
          Let's keep in touch
        </HeaderCtaStyled>
      </HeaderItemStyled>
    </HeaderWrapperStyled>
  </HeaderStyled>
)

const HeaderStyled = styled.header`
  position: absolute;
  top: 0;
  overflow: hidden;
  left: 0;
  z-index: 3;
  width: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  ${breakpoint.lg`
    display: flex;
    max-width: 80px;
    padding: 0;
    height: 100vh;
    left: calc(100% - 80px);
  `}
`
const HeaderWrapperStyled = styled.div`
  ${containerStyled}
  display: flex;
  ${breakpoint.lg`
    align-item: center;
    justify-content: center;
  `}
`
const HeaderItemStyled = styled.div``
const HeaderCtaStyled = styled.a`
  ${ctaStyled}
  display: block;
  font-size: 14px;
  white-space: nowrap;
  ${breakpoint.lg`
    transform: rotateZ(90deg);
  `}
`

export default Header
