export const variables = {
  // colors
  primaryColor: "#0A0A0A",
  secondaryColor: "#fff",
  accentColor: "#4b3a58",
  // font size
  mediumFontSizeDekstop: 32,
  mediumFontSizeMobile: 24,
  lineHeight: 1.6,
  // font family
  fontBold: "Canela-Medium",
  fontRegular: "Canela-Light",
}
