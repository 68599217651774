import { createGlobalStyle } from "styled-components"
import { resetCss } from "./reset"
import { breakpoint } from "./mixins"
import { normalizeCss } from "./normalize"
import { variables } from "./variables"
export const GlobalStyle = createGlobalStyle`
  ${normalizeCss}
  ${resetCss}
  html, body {
    font-family: ${variables.fontRegular}, sans-serif;
    color: white;
    background-color: ${variables.primaryColor};
    overflow-x: hidden;
    
  }
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`

export const containerStyled = `
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 32px;
`
export const ctaStyled = `
  text-transform: uppercase;
  text-decoration: none;
  color: white;
  letter-spacing: 2px;
  font-family: ${variables.fontBold};
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  font-size: 14px;
  ${breakpoint.md`
    font-size: 16px;
  `}
  &:hover {
    &:after {
      left: 5px;
    }
  }
  &:after {
    content: '⟶';
    margin-left: 5px;
    position: relative;
    top: -2px;
  }
`

export const truncateStyled = `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  `
