import React from "react"
import { containerStyled } from "@Assets/styles/globalStyle"
import styled from "styled-components"
import PropTypes from "prop-types"
const BackgroundLines = ({ isBlack }) => (
  <BackgroundLinesStyled className={isBlack ? "is-black" : ""}>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </BackgroundLinesStyled>
)

const BackgroundLinesStyled = styled.div`
  width: 100vw;
  ${containerStyled}
  height: 100%;
  display: flex;
  margin: 0 auto;
  z-index: 1;
  justify-content: space-between;
  position: fixed;
  top: 0;
  pointer-events: none;
  left: 50%;
  transform: translateX(-50%);
  span {
    height: 100%;
    width: 1px;
    background-color: white;
    opacity: 0.04;
  }
  &.is-black {
    span {
      background-color: black;
    }
  }
`

BackgroundLines.propTypes = {
  isBlack: PropTypes.bool,
}

BackgroundLines.defaultProps = {
  isBlack: false,
}

export default BackgroundLines
