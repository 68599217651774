import React from "react"
import { breakpoint } from "@Assets/styles/mixins"
import { containerStyled } from "@Assets/styles/globalStyle"
import styled from "styled-components"
import { variables } from "@Assets/styles/variables"
import instagramIcon from "@Assets/images/icons/instagram-icon.svg"
import vimeoIcon from "@Assets/images/icons/vimeo-icon.svg"

const Footer = () => (
  <FooterStyled>
    <FooterWrapperStyled>
      <FooterItemStyled>
        <FooterTextStyled>
          © Matteo Molteni {new Date().getFullYear()} – All right reserved
        </FooterTextStyled>
      </FooterItemStyled>
      <FooterItemStyled>
        <FooterIconsWrapperStyled>
          <FooterIconStyled
            href="https://vimeo.com/matteomolteni"
            target="_blank"
          >
            <img alt="vimeo-icon" src={vimeoIcon} />
          </FooterIconStyled>
          <FooterIconStyled
            href="https://www.instagram.com/byffmm.directors/"
            target="_blank"
          >
            <img alt="instagram-icon" src={instagramIcon} />
          </FooterIconStyled>
          {/* <FooterIconStyled href="google.com" target="_blank">
            <img alt="facebook-icon" src={facebookIcon} />
          </FooterIconStyled> */}
        </FooterIconsWrapperStyled>
      </FooterItemStyled>
    </FooterWrapperStyled>
  </FooterStyled>
)

const FooterStyled = styled.footer`
  width: 100%;
  padding: 48px 0;
`

const FooterWrapperStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  ${containerStyled}
  ${breakpoint.lg`
    flex-wrap: nowrap;
    align-item: center;
    justify-content: space-between;
  `}
`

const FooterItemStyled = styled.div`
  width: 100%;
`
const FooterTextStyled = styled.p`
  letter-spacing: 2px;
  font-family: ${variables.fontRegular};
  text-align: center;
  font-size: 14px;
  padding-bottom: 16px;
  ${breakpoint.lg`
    text-align: left;
    padding-bottom: 0;
  `}
`
const FooterIconsWrapperStyled = styled.div`
  display: flex;
  justify-content: center;
  ${breakpoint.lg`
  align-item: center;
    justify-content: flex-end;
  `}
`
const FooterIconStyled = styled.a`
  margin: 0 8px;
  cursor: pointer;
`
export default Footer
